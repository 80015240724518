import React, {
	useEffect, useRef, useContext, useState,
} from 'react';
import Head from 'next/head';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import {
	Container, Flex, Box, Heading, Link as ChakraLink, Text, Image, Icon,
	UnorderedList, ListItem,
	Button, IconButton,
	useDisclosure, useBreakpointValue,
	Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerBody,
	Stack, Divider,
	Menu, MenuButton, MenuList, MenuItem,
} from '@chakra-ui/react';
import NProgress from 'nprogress';
import { jsonLdScriptProps } from 'react-schemaorg';
import 'focus-visible/dist/focus-visible';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import {
	faDatabase,
	faLandmark,
	faBars,
	faSignOutAlt, faUser,
} from '@fortawesome/free-solid-svg-icons';
import { ContextStore } from '../contexts/authContext';
import MenuFavorites from './MenuFavorites';

const Page = ({
	children, title, containerMaxW, menuNoShow, isNoFooter,
}) => {
	const router = useRouter();
	const { userState } = useContext(ContextStore);
	const [isLogin, setIsLogin] = useState(false);
	const [isRetrived, setIsRetrived] = useState(false);

	useEffect(() => {
		const handleStart = () => {
			NProgress.start();
		};
		const handleStop = () => {
			NProgress.done();
		};

		router.events.on('routeChangeStart', handleStart);
		router.events.on('routeChangeComplete', handleStop);
		router.events.on('routeChangeError', handleStop);

		return () => {
			router.events.off('routeChangeStart', handleStart);
			router.events.off('routeChangeComplete', handleStop);
			router.events.off('routeChangeError', handleStop);
		};
	}, [router]);

	useEffect(() => {
		if (userState.user) {
			setIsLogin(true);
		} else {
			setIsLogin(false);
		}
	}, [userState]);

	const logout = async () => {
		router.push(`${process.env.NEXT_PUBLIC_AUTH_API_PATH}/logout?redirect=${window?.location?.href}`);
		// contextLogout();
		// router.replace('/');
	};

	const mobileMenuShow = useBreakpointValue({
		base: true, md: false,
	});
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = useRef();

	return (
		<div>
			<Container maxW={containerMaxW ?? 'container.xl'} minH="80vh" pb={8}>
				<Head>
					<title>
						{title ? `${title ?? ''} | Open Data Portal` : 'Open Data Portal'}
					</title>
					<script
						{...jsonLdScriptProps({
							'@context': 'https://schema.org',
							'@type': 'WebSite',
							url: 'https://odportal.tw',
							potentialAction: {
								'@type': 'SearchAction',
								target: {
									'@type': 'EntryPoint',
									urlTemplate: 'https://odportal.tw/datasets/search?q={search_term_string}',
								},
								'query-input': 'required name=search_term_string',
							},
						})}
					/>
				</Head>
				<div id="top" />
				{mobileMenuShow ? (
					<>
						<Flex mt={4} as="nav" justifyContent="space-between" alignItems="center">
							<Heading as={title ? 'h2' : 'h1'} size="lg" id="title" fontWeight={400} fontSize="xl">
								<NextLink passHref href="/">
									<ChakraLink>
										<Text as="span" fontWeight={600}>
											<Text as="span" color="pryRed">OD</Text>
											Portal |
											{' '}
										</Text>
										<Text as="span" color="pryRed">
											臺灣
											<strong>開放資料</strong>
											集散地
										</Text>
									</ChakraLink>
								</NextLink>
							</Heading>
							{!menuNoShow && (
								<IconButton
									bgColor="transparent"
									color="#000"
									icon={<Icon as={Fa} icon={faBars} />}
									ref={btnRef}
									onClick={() => onOpen()}
									p={0}
									mx={0}
								/>
							)}
						</Flex>
						<Drawer
							size="full"
							isOpen={isOpen}
							placement="right"
							onClose={onClose}
							finalFocusRef={btnRef}
						>
							<DrawerOverlay />
							<DrawerContent pt={6} px={4}>
								<DrawerCloseButton color="#000" />
								<DrawerBody mx={0} px={0} pb={20}>
									{isLogin ? (
										<Flex py={4} my={4} justifyContent="center" alignItems="center" pl={3} color="textGray" borderBottom="1px solid" borderColor="lineGray">
											<Flex alignItems="center">
												<Icon as={Fa} icon={faUser} size="2x" mr={2} />
												<Text>{userState?.name}</Text>
												<Button m={0} ml={2} variant="link" onClick={() => { logout(); onClose(); }}>
													<Text fontSize="sm">登出</Text>
												</Button>
											</Flex>
										</Flex>
									) : null}
									<Stack mt={10} direction="column" spacing={6} display="flex">
										<NextLink passHref href="/datasets">
											<ChakraLink>
												<Flex justifyContent="center" alignItems="center">
													<Text as="span" fontSize="lg" fontWeight="bold">
														所有
														<Text fontWeight="inherit" as="strong">開放資料</Text>
														集
														<Text d="none" as="strong">Open Data</Text>
													</Text>
												</Flex>
											</ChakraLink>
										</NextLink>
										<Divider />
										<NextLink passHref href="/governments">
											<ChakraLink>
												<Flex justifyContent="center" alignItems="center">
													<Text as="span" fontSize="lg" fontWeight="bold">所有資料來源</Text>
												</Flex>
											</ChakraLink>
										</NextLink>
										<Divider />
										<NextLink passHref href="/collections/favorite">
											<ChakraLink>
												<Flex justifyContent="center" alignItems="center">
													<Text as="span" fontSize="lg" fontWeight="bold">收藏庫</Text>
												</Flex>
											</ChakraLink>
										</NextLink>
										<Divider />
									</Stack>
									{!isLogin ? (
										<Box mt={10} d="flex" flexDirection="column" alignItems="center">
											<Button
												size="lg"
												as={ChakraLink}
												colorScheme="red"
												bgColor="pryRed"
												href={`${process.env.NEXT_PUBLIC_AUTH_API_PATH}/register?redirect=${window?.location?.href}`}
											>
												<Text fontSize="lg" color="white">註冊</Text>
											</Button>
											<ChakraLink href={`${process.env.NEXT_PUBLIC_AUTH_API_PATH}/login?redirect=${window?.location?.href}`}>
												<Text mt={4} fontSize="lg">登入</Text>
											</ChakraLink>
										</Box>
									) : null}
								</DrawerBody>
							</DrawerContent>
						</Drawer>
					</>
				) : (
					<Flex
						flexWrap="wrap"
						justifyContent={['flex-end', 'space-between']}
						alignItems="center"
						backgroundColor="bgGray"
						mt={3}
						pt={3}
						position="sticky"
						top={0}
						zIndex={999}
					>
						<Box>
							<header>
								<Heading as={title ? 'h2' : 'h1'} size="lg" id="title" fontWeight={400}>
									<NextLink passHref href="/">
										<ChakraLink>
											<Text as="span" fontWeight={600}>
												<Text as="span" color="pryRed">OD</Text>
												Portal |
												{' '}
											</Text>
											<Text as="span" color="pryRed">
												臺灣
												<strong>開放資料</strong>
												集散地
											</Text>
										</ChakraLink>
									</NextLink>
								</Heading>
								{!menuNoShow ? (
									<Flex justifyContent="space-between">
										<Box as="nav">
											<UnorderedList listStyleType="none" ml={0} pl={0} mt={3}>
												<ListItem d="inline" mr={3}>
													<NextLink passHref href="/datasets">
														<ChakraLink>
															<Icon as={Fa} icon={faDatabase} mr={1} />
															<Text as="span" fontSize="sm">
																所有
																<Text fontWeight="inherit" as="strong">開放資料</Text>
																集
																<Text d="none" as="strong">Open Data</Text>
															</Text>
														</ChakraLink>
													</NextLink>
												</ListItem>
												<ListItem d="inline" mr={3}>
													<NextLink passHref href="/governments">
														<ChakraLink>
															<Icon as={Fa} icon={faLandmark} mr={1} />
															<Text as="span" fontSize="sm">所有資料來源</Text>
														</ChakraLink>
													</NextLink>
												</ListItem>
											</UnorderedList>
										</Box>
									</Flex>
								) : null}
							</header>
						</Box>
						{!menuNoShow && !isLogin ? (
							<Box>
								<UnorderedList display="flex" alignItems="center" listStyleType="none" ml={0} pl={0} mt={3}>
									<ListItem d="inline" mr={3}>
										<ChakraLink href={`${process.env.NEXT_PUBLIC_AUTH_API_PATH}/login?redirect=${window?.location?.href}`}>
											<Text fontSize="sm">登入</Text>
										</ChakraLink>
									</ListItem>
									<ListItem d="inline" mr={3}>
										<Button
											size="sm"
											as={ChakraLink}
											colorScheme="red"
											bgColor="pryRed"
											href={`${process.env.NEXT_PUBLIC_AUTH_API_PATH}/register?redirect=${window?.location?.href}`}
										>
											<Text fontSize="sm" color="white">註冊</Text>
										</Button>
									</ListItem>
								</UnorderedList>
							</Box>
						) : null}
						{!menuNoShow && isLogin ? (
							<Flex justifyContent="center" alignItems="center">
								<Menu isLazy closeOnSelect={false}>
									<MenuFavorites isRetrived={isRetrived} setIsRetrived={setIsRetrived} />
								</Menu>
								<Menu closeOnSelect={false} direction="rtl">
									<MenuButton as="Button">
										<Flex alignItems="center" pr={3} color="textGray">
											<Text mr={2} fontSize="sm">{userState?.name}</Text>
											<Icon as={Fa} icon={faUser} size="1x" />
										</Flex>
									</MenuButton>
									<MenuList bgColor="transparent">
										<MenuItem onClick={() => logout()} display="flex" justifyContent="flex-end">
											<Icon as={Fa} icon={faSignOutAlt} color="pryOrange" mr={2} size="1x" />
											<Text textAlign="right" fontSize="sm">登出</Text>
										</MenuItem>
									</MenuList>
								</Menu>
							</Flex>
						) : null}
					</Flex>
				)}
				{children}
			</Container>
			{!isNoFooter && (
				<Box as="footer" backgroundColor="#00344F" py={5} mt={10}>
					<Container maxW="container.lg" pt={4}>
						<Flex justifyContent="space-between" alignItems="flex-start" d={['box', 'flex']}>
							<Flex alignItems="center">
								<Image d="inline-block" src="/logo_w.svg" alt="BHIS" w="32px" h="32px" />
								<Text as="span" size="xs" color="#f2f2f2" fontWeight="800">
									BHIS
									{new Date().getFullYear()}
								</Text>
							</Flex>
							<Box w="250px" maxW="100%" my={2}>
								<ChakraLink fontSize="xs" color="#aac" d="block" my={1} target="_blank" href="https://data.gov.tw">政府開放資料平臺</ChakraLink>
								<ChakraLink fontSize="xs" color="#aac" d="block" my={1} target="_blank" href="https://data.gov.tw/license">政府資料開放授權條款</ChakraLink>
							</Box>
							<Box w="250px" maxW="100%" my={2}>
								<ChakraLink fontSize="xs" color="#aac" d="block" my={1} target="_blank" href="https://dashboard1999.kcg.gov.tw">高雄市 1999 儀表板</ChakraLink>
								<ChakraLink fontSize="xs" color="#aac" d="block" my={1} target="_blank" href="https://sdg.tw">SDG Indicators</ChakraLink>
								<ChakraLink fontSize="xs" color="#aac" d="block" my={1} target="_blank" href="https://www.bhis.com.tw/work/od-portal/">
									關於我們
								</ChakraLink>
							</Box>
						</Flex>
						<Box mt={6} pt={3} borderTop="1px solid #777">
							<Text fontSize="xs" color="#aac">
								Copyright © 2020~
								{new Date().getFullYear()}
								.
							</Text>
						</Box>
					</Container>
				</Box>
			)}
			<style jsx global>
				{`
					.js-focus-visible button:focus, .js-focus-visible a:focus {
						box-shadow: 0 0 0 1px #BEBEBE;
					}
					.js-focus-visible button:focus:not(.focus-visible), .js-focus-visible a:focus:not(.focus-visible) {
						outline: none;
					}
				`}
			</style>
		</div>
	);
};

export default Page;
